@import url('https://fonts.googleapis.com/css?family=Sawarabi+Gothic&display=swap');

body {
  margin: 0;
  font-family: "Sawarabi Gothic","Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* -apple-system, BlinkMacSystemFont,  */
/* li{
  font-family: "M+PLUS+Rounded+1c" !important;
} */

code {
  /* font-family: 'M PLUS Rounded 1c',source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
    font-family: 'Sawarabi Gothic', sans-serif;
}
