.keywordmap {
  box-shadow: inset 1px 1px 6px 2px rgba(0,0,0, .25);
  width: 100%;
  cursor: auto;
}

.filter{
  border: solid 5px white;
  background-color: rgba(208, 211, 213, 0.233);
}

.filterRemove{
  border: solid 5px white;
  background-color: rgba(238, 236, 225);
}

.deleteValue{
  position: relative;
  right: 1.5rem;
  top: -.1rem;
  cursor: pointer;
}

.basicTable{
  white-space: nowrap;
}

tbody.scrollBody{
  overflow-y:scroll;
  display:block;
  width: 100%;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.flexible-modal {
  position: absolute;
  padding: 20px;
  z-index: 99999;
  background: white;
}


.flexible-modal-mask {
  position: fixed;
  height: 100%;
  z-index: 99999;
  background: rgba(55, 55, 55, 0.8);
  top:0;
  left:0;
  right:0;
  bottom:0;
  box-shadow: 4px 4px;
}

.flexible-modal-drag-area{
  background: rgba(108,117,125,0.322); 
  height: 50px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
  margin-bottom: 10;
}

.flexible-modal-drag-area-left{
  height: 0;
  width: 0;
}

.flexible-modal-drag-area-right{
  height: 0;
  width: 0;
}

.brandBody {
  font-size: 1rem;
  font-family: 'Sawarabi Gothic',sans-serif;
}

.toggleable__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .8s cubic-bezier(0,1,0,1);
}

.toggleable__control:checked ~ .toggleable__content {
  transition-timing-function: ease-in-out;
  max-height: 5000px;
}

.hidden--visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  display: none;
}

body {
  font-size: 1rem;
  line-height: 1.333;
  font-family: 'Sawarabi Gothic', sans-serif;
  margin: 0 auto;
  min-width: 1000px;
}

.toggleable__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.toggleable__content {
  display: block;
  justify-content: space-between;
  align-items: center;
}

.toggleable__control:checked ~ .toggleable__content {
  margin-bottom: 0;
}

.toggleable__label::after {
  width: 20px;
  height: 20px;
  content: '';
  display: inline-block;
  background-image: url("./images/icons/accordion.png");
  background-size: contain;
  background-repeat:  no-repeat;
  vertical-align: bottom;
}

.toggleable__control:checked ~ .toggleable__label::after {
  width: 20px;
  height: 20px;
  content: '';
  display: inline-block;
  background-image: url("./images/icons/accordion.png");
  background-size: contain;
  background-repeat:  no-repeat;
  vertical-align: bottom;
}

.toggleablebrand__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .5s cubic-bezier(0,1,0,1);
}

.toggleablebrand__control:checked ~ .toggleablebrand__content {
  transition-timing-function: ease-in-out;
  max-height: 10000px;
}

.accordionDisp {
  display: flex;   
  justify-content: flex-start;
  font-size: 100%;
  text-align: left;
}

.over-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px
}

.life-over-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px
}

.tooltip-inner {
  opacity: 1 !important;
  pointer-events: none;
  text-align: left !important;
  color: #000 !important;
  background-color: rgb(255, 255, 255) !important;
  border-width: 2px;
  border-style: solid !important;
  border-color: rgb(107, 107, 107) !important;
  padding: 10px;
  position: relative;
  left: 30%;
  display: inline-block;
  max-width: 42% !important;;
}

.arrow {
  display: none !important;
}

.contentsLeft {
  text-align: left;
}

.hidden--visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  display: none;
}

.toggleablebrand__label {
  display: block;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-align: right;
}

.toggleablebrand__content {
  display: block;
  justify-content: space-between;
  align-items: center;
}

.toggleablebrand__control:checked ~ .toggleablebrand__content {
  margin-bottom: 0;
}

.toggleablebrand__label::after {
  width: 20px;
  height: 20px;
  content: '';
  display: inline-block;
  background-image: url("./images/icons/accordion.png");
  background-size: contain;
  background-repeat:  no-repeat;
  vertical-align: middle;
}

.toggleablebrand__control:checked ~ .toggleablebrand__label::after {
  width: 20px;
  height: 20px;
  content: '';
  display: inline-block;
  background-image: url("./images/icons/accordion.png");
  background-size: contain;
  background-repeat:  no-repeat;
  vertical-align: middle;
}

.checkboxOr{
  box-sizing: border-box;
  border-radius: 8px;

}

.oi:empty:before {
  width: 1em;
  text-align: center;
  box-sizing: content-box;
}

.freeform-style {
  white-space: normal!important;
}

.dx-g-bs4-table-cell{
  overflow: unset;
  text-overflow: unset;
}

.modal-body {
  padding: .4rem !important;
}

/* .oi-arrow-thick-top:before {
  width: 10px;
  height: 10px;
  content: '';
  display: inline-block;
  background-image: url("./images/icons/accordion_close.png");
  background-size: cover;
  vertical-align: middle;
}

.oi-arrow-thick-bottom:before {
  width: 10px;
  height: 10px;
  content: '';
  display: inline-block;
  background-image: url("./images/icons/accordion_open.png");
  background-size: cover;
  vertical-align: middle;
} */

.saveButton{
  background-color: rgb(108,117,125);
  color: #f5f5f5; 
  border-color: rgba(108, 117, 125, 0);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
  margin-left: 2px;
}

.table {
  margin-bottom: 0px !important;
}

.saveName{
  border-radius: .2rem;
}

.modal-80w {
  width: 80% !important;
}

.modal-70w {
  width: 70% !important;
}

.modal-60w {
  width: 60% !important;
}

.modal-50w {
  width: 50% !important;
}

.modal-40w {
  width: 40% !important;
}

.modal-30w {
  width: 30% !important;
}

@media screen and (max-width: 1440px) {
  .modal-80w {
    width: 80% !important;
    /* top: 60% !important;
    height: 900px !important; */
  }
  .modal-70w {
    width: 85% !important;
  }
  .modal-60w {
    width: 80% !important;
  }
  .modal-50w {
    width: 70% !important;
  }
  .modal-40w {
    width: 50% !important;
  }
  .modal-30w {
    width: 40% !important;
  }
}

@media screen and (max-width: 768px) {
  .modal-70w {
    width: 90% !important;
  }
  .modal-60w {
    width: 90% !important;
  }
  .modal-50w {
    width: 95% !important;
  }
  .modal-40w {
    width: 60% !important;
  }
  .modal-30w {
    width: 50% !important;
  }
}

.modal-dialog {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: none!important;
}

.modal-header {
  background-color: rgb(208,211,213);
}

.modal-content {
  width: auto !important;
}

.modal-header {
  display: block !important;
  padding: 0.8rem 0.5rem !important;
}

div.fade.in.modal {
  display: flex !important;
}

.searchState {
  display: none;
}

.scrollHandleBrand{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 1330px;
  margin-left: 5%;
  margin-right: 5%;
}

.scrollHandleLifestyle{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 830px;
  margin-left: 5%;
  margin-right: 5%;
}

.btn-outline-danger{
  width: auto;
}
.btn-outline-primary {
  width: auto;
}

.btn-outline-danger-unchecked {
  color: #000000 !important;
  border-color: #fe9ca6 !important;
}
.btn-outline-danger-unchecked:hover {
  background-color: #ffffff !important;
}
.btn-outline-danger-checked {
  color: #000000 !important;
  background-color: #fd7785 !important;
  border-color: #ff4e5f !important;
}

.btn-outline-primary-unchecked {
  color: #000000 !important;
  border-color: #9ecdff !important;
}
.btn-outline-primary-unchecked:hover {
  background-color: #ffffff !important;
}
.btn-outline-primary-checked {
  color: #000000 !important;
  background-color: #66b0fe !important;
  border-color: #49a1ff !important;
}

input[type=radio] {
  display: none;
}

@media print {
  .print{
    font-size: 65%;
  }
  .scrollHandleBrand{
    overflow-y: visible;
    overflow-x: visible;
    height: 1330px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .scrollHandleLifestyle{
    overflow-y: visible;
    overflow-x: visible;
    height: 830px;
    margin-left: 5%;
    margin-right: 5%;
  }
}